<template>
  <v-layout style="display: block;">
    <v-card flat class="py-3 cross-selling-layout">
      <v-card-title
        class="pb-0 pt-0 text-body-3 font-weight-bold d-flex justify-center cross-selling-title"
        >{{ $t(`${"crossSelling.title"}`) }}
      </v-card-title>
      <v-card-text class="pb-0 pt-1">
        <div
          v-if="$te('crossSelling.content')"
          class="pa-2"
          v-html="$t('crossSelling.content')"
        ></div>
        <ProductListSlider :productId="product.productId" :layout="223" />
      </v-card-text>
    </v-card>
  </v-layout>
</template>
<style lang="scss">
.cross-selling-layout .v-btn {
  border: none !important;
}
.cross-selling-layout {
  .cross-selling-title {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      font-size: 16px !important;
    }
    font-size: 20px !important;
  }
}
</style>

<script>
export default {
  name: "CrossSellingDialog",
  props: {
    product: { type: Object, required: true }
  },
  components: {
    ProductListSlider: () =>
      import("@/components/product/ProductListSlider.vue")
  },
  methods: {},
  mounted() {
    console.log(this.products);
    global.EventBus.$on("addToFavDialog", () => {
      this.$emit("submit", false);
    });
  }
};
</script>
